@font-face {
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    src: local(""), url("../fonts/mulish-v10-latin-regular.woff2") format("woff2"), url("../fonts/mulish-v10-latin-regular.woff") format("woff");
}

@font-face {
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    src: local(""), url("../fonts/mulish-v10-latin-700.woff2") format("woff2"), url("../fonts/mulish-v10-latin-700.woff") format("woff");
}

@font-face {
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    src: local(""), url("../fonts/mulish-v10-latin-800.woff2") format("woff2"), url("../fonts/mulish-v10-latin-800.woff") format("woff");
}

@font-face {
    font-family: Mulish;
    font-style: italic;
    font-weight: 400;
    src: local(""), url("../fonts/mulish-v10-latin-italic.woff2") format("woff2"), url("../fonts/mulish-v10-latin-italic.woff") format("woff");
}

@font-face {
    font-family: Mulish;
    font-style: italic;
    font-weight: 700;
    src: local(""), url("../fonts/mulish-v10-latin-700italic.woff2") format("woff2"), url("../fonts/mulish-v10-latin-700italic.woff") format("woff");
}

@font-face {
    font-family: Lora;
    font-style: normal;
    font-weight: 400;
    src: local(""), url("../fonts/lora-v23-latin-regular.woff2") format("woff2"), url("../fonts/lora-v23-latin-regular.woff") format("woff");
}

@font-face {
    font-family: Lora;
    font-style: normal;
    font-weight: 700;
    src: local(""), url("../fonts/lora-v23-latin-700.woff2") format("woff2"), url("../fonts/lora-v23-latin-700.woff") format("woff");
}

@font-face {
    font-family: Lora;
    font-style: italic;
    font-weight: 400;
    src: local(""), url("../fonts/lora-v23-latin-italic.woff2") format("woff2"), url("../fonts/lora-v23-latin-italic.woff") format("woff");
}

@font-face {
    font-family: Lora;
    font-style: italic;
    font-weight: 700;
    src: local(""), url("../fonts/lora-v23-latin-700italic.woff2") format("woff2"), url("../fonts/lora-v23-latin-700italic.woff") format("woff");
}
